body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.calendar {
    margin: 10px;
}
.fc-button {
    color: #fff;
    background-color: #0d47a1 !important;
    border-color: #0d47a1 !important;
}
.fc-button:hover {
    background-color: #05409a !important;
}
.fc-left > h2 {
    font-size: 1.25em !important;
}
